declare global {
  namespace JSX {
    interface IntrinsicElements {
      'iconpark-icon': any
    }
  }
}

import classNames from 'classnames'
import { CSSProperties } from 'react'
import styles from './styles.module.scss'

type IIconParkProps = {
  name: string
  className?: string
  style?: CSSProperties
}

export default function IconPark({ name, className, style }: IIconParkProps) {
  return (
    <iconpark-icon
      name={name}
      style={style}
      class={classNames(styles.iconparkIcon, className)}
    />
  )
}
// https://iconpark.oceanengine.com/projects/12394/detail
const ICON_PARK_SCRIPT_URL =
  'https://lf1-cdn-tos.bytegoofy.com/obj/iconpark/icons_32178_104.b3706d57358bbe9ab1342b2f92979059.es5.js'

export function IconParkScript() {
  return (
    <>
      <script defer src={ICON_PARK_SCRIPT_URL} />
    </>
  )
}
